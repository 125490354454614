import { useContext } from 'react';
import useScript from '../ui/hooks/useScript';
import ChannelContext from '@jetshop/core/components/ChannelContext';

export function LipscoreScript({ apiKey }) {
  const { selectedChannel } = useContext(ChannelContext);
  const langData = selectedChannel.language.culture.split('-');
  const lang = langData?.length > 1 ? langData[1].toLowerCase() : '';
  if (typeof window !== 'undefined') {
    window.lipscoreInit = function() {
      window.lipscore.init({
        apiKey: apiKey
      });
    };
  }
  useScript(`//static.lipscore.com/assets/${lang}/lipscore-v1.js`);

  return null;
}
