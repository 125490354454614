import React, { useState } from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import SubMenu from './SubMenu';
import { theme } from '../../../Theming/Theming';
import { ExtraMenuLinks } from '../Util/ExtraMenuLinks';

const DesktopNavWrapper = styled('div')`
  height: ${theme.desktopHeader.nav.height.lg + 'px'};
  transition: height 0.8s ease;
  width: 100%;
  //position: relative;
  z-index: 0;
  margin-left: 30px;
  ${theme.only.md} {
    height: ${theme.desktopHeader.nav.height.md + 'px'};
  }
  &[data-scrolling='true'] {
    height: ${theme.desktopHeader.nav.height.scroll + 'px'};
  }
`;

const List = styled('ul')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  &.sub-menu-open {
    > li:not(.sub-menu-open) {
      //opacity: 0.7;
    }
  }

  > li {
    list-style: none;
    padding: 0 ${theme.desktopHeader.nav.padding};
    position: relative;
    @media (max-width: 1350px) {
      padding: 0 0.7rem;
    }
    > a {
      position: relative;
      height: 100%;
      display: block;
      text-transform: uppercase;
      font-size: ${theme.desktopHeader.nav.fontSize[0]};
      font-weight: ${theme.desktopHeader.nav.fontWeight} !important;
      line-height: ${theme.desktopHeader.nav.height.lg + 'px'};
      ${theme.only.md} {
        line-height: ${theme.desktopHeader.nav.height.md + 'px'};
      }
      @media (min-width: 100rem) { {
        font-size: ${theme.desktopHeader.nav.fontSize[1]};
      }
      color: ${theme.desktopHeader.color};
    }
    a.active {
      text-decoration: underline;
    }
    a.selected {
      text-decoration: underline;
    }
    &.sub-menu-open {
      .sub-menu-wrapper {
        display: block;
      }
    }
  }
  &[data-scrolling='true'] {
    > li {
      > a {
        line-height: ${theme.desktopHeader.nav.height.scroll + 'px'};
      }
    }
  }
`;

const SubMenuMarker = styled('span')`
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 10px;
  display: block;
  background: ${theme.desktopHeader.nav.barHoverColor};
`;

const DesktopNav = ({ categoryTreeRoots, scrolling }) => {
  const [activeCategory, setActiveCategory] = useState(null);
  const [subMenuOpen, setSubMenu] = useState(false);

  function handleCatOpen(e, category, setActiveCategory, setSubMenu) {
    if (category.hasSubcategories) {
      e.preventDefault();
      setActiveCategory(category);
      setSubMenu(true);
    } else setSubMenu(false);
  }

  function isActiveMenu(category, activeCategory, subMenuOpen) {
    if (subMenuOpen && activeCategory && activeCategory.id === category.id)
      return true;
  }

  return (
    <DesktopNavWrapper
      data-scrolling={scrolling}
      onMouseLeave={() => setSubMenu(false)}
    >
      <List
        data-scrolling={scrolling}
        className={subMenuOpen ? 'sub-menu-open' : null}
      >
        {categoryTreeRoots.map((category, index) => (
          <li
            key={category.id}
            onMouseEnter={e =>
              handleCatOpen(e, category, setActiveCategory, setSubMenu)
            }
            className={
              isActiveMenu(category, activeCategory, subMenuOpen)
                ? 'sub-menu-open'
                : ''
            }
          >
            <CategoryLink
              category={category}
              className="secondary-font"
              //onClick={() => setSubMenu(false);
              /*onClick={e =>
                handleCatOpen(e, category, setActiveCategory, setSubMenu)
              }*/
            >
              {category.name}
              {isActiveMenu(category, activeCategory, subMenuOpen) && (
                <SubMenuMarker />
              )}
            </CategoryLink>
            {isActiveMenu(category, activeCategory, subMenuOpen) && (
              <SubMenu
                activeCategory={activeCategory}
                category={category}
                setActiveCategory={setActiveCategory}
                setSubMenu={setSubMenu}
                scrolling={scrolling}
                index={index}
              />
            )}
          </li>
        ))}
        <ExtraMenuLinks />
      </List>
    </DesktopNavWrapper>
  );
};

export default DesktopNav;
