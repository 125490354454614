import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import FooterContent, { FooterContentWrapper } from './FooterContent';
import NewsletterBox from './NewsletterBox';
import Logo from '../Header/Logo';
import { theme } from '../../Theming/Theming';
import LipscoreWidget from '../../Lipscore/LipscoreWidget';

const FooterWrapper = styled('footer')`
  color: ${theme.footer.color};
  background: ${theme.footer.background};
  z-index: 2;
  a,
  a:active {
    text-decoration: none;
    color: ${theme.footer.color};
  }
  .footer-upper-wrapper {
    padding: 50px 0 0;
    a {
    }
  }
  .footer-content {
    padding: 3rem 0;
    ${FooterContentWrapper} {
      padding-top: 0.5rem;
      margin-bottom: 3rem;
      p:first-child {
        display: inline-block;
        color: ${theme.colors.greys[3]};
        ${theme.below.md} {
          display: none;
        }
      }
      .jetshop-text {
        ${theme.above.md} {
          float: right;
        }
        text-align: center;
      }
    }
  }
`;

const LogoWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  ${theme.footer.logoBackground
    ? `background: ${theme.footer.logoBackground};`
    : ``};

  > div {
    width: 100%;
    max-width: 500px;
  }
`;

const LipScoreWrapper = styled.div`
  text-align: center;
  padding: 2rem 0;
  background: #eee;
  > div {
    margin: 1rem 0;
  }
`;

const Footer = () => {
  return (
    <>
      {theme.config.lipScore && (
        <LipScoreWrapper>
          <LipscoreWidget type="service-small" />
        </LipScoreWrapper>
      )}
      <FooterWrapper>
        <div className="newsletter-box">
          <NewsletterBox />
        </div>
        <div className="footer-content">
          <MaxWidth>
            <FooterContent />
          </MaxWidth>
        </div>
      </FooterWrapper>

      {theme.footer.logoBackground && (
        <LogoWrapper>
          <div>
            <Logo size={[500]} />
          </div>
        </LogoWrapper>
      )}
    </>
  );
};

export default Footer;
