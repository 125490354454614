import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import PagePreviewQuery from './PagePreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import routeQuery from './RouteQuery.gql';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import './Theming/globalStyles';
import { FontHandler } from '../fonts/FontHandler';
import { theme } from './Theming/Theming';
import CookieConsent from './Util/CookieConsent';
import NewsletterPopup from './Util/NewsletterPopup';
import SiteContent from './SiteContent/SiteContentProvider';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import KlaviyoProvider from './Klaviyo';
import useLipScore from './Lipscore/useLipScore';
import { LipscoreScript } from './Lipscore/LipscoreScript';

const NotFound = loadable(() => import('./NotFoundPage'), {
  fallback: <LoadingPage />
});

const LogInPage = loadable(() => import('./Auth/LogInPage'), {
  fallback: <LoadingPage />
});

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: <LoadingPage />
});

export const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: <LoadingPage />
});
const LoadableSignUpPage = loadable(() => import('./Auth/Signup/SignUpPage'), {
  fallback: <LoadingPage />
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: <LoadingPage />
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: <LoadingPage />
});
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: <LoadingPage />
});

//BLOG IMPORTS
const LoadableBlogPage = loadable(() => import('./Blog/BlogPage'), {
  fallback: <LoadingPage />
});

const LoadableBlogArticle = loadable(() => import('./Blog/BlogArticle'), {
  fallback: <LoadingPage />
});

function Shop() {
  const { routes } = useShopConfig();
  const { selectedChannel } = useContext(ChannelContext);
  const lipScoreApiKey = useLipScore(selectedChannel);
  return (
    <GenericError>
      <ModalProvider>
        <SiteContent>
          <Container>
            {lipScoreApiKey && <LipscoreScript apiKey={lipScoreApiKey} />}
            <FontHandler />
            <KlaviyoProvider selectedChannel={selectedChannel} />
            <Helmet defaultTitle={theme.config.storeName} />
            <ProductListProvider queries={productListQueries}>
              <PaginationProvider defaultProductsPerPage={24}>
                <Header />
                <Content>
                  <Switch>
                    <Route
                      exact
                      path={`${theme.config.routes.blog.path}/:cat/:item`}
                    >
                      <LoadableBlogArticle
                        configObject={theme.config.routes.blog}
                      />
                    </Route>
                    <Route exact path={`${theme.config.routes.blog.path}/:cat`}>
                      <LoadableBlogPage
                        configObject={theme.config.routes.blog}
                      />
                    </Route>
                    <Route exact path={theme.config.routes.blog.path}>
                      <LoadableBlogPage
                        configObject={theme.config.routes.blog}
                      />
                    </Route>

                    <Route exact path="/">
                      <LoadableStartPage />
                    </Route>
                    <Route path={theme.config.routes.favourites.path}>
                      <Favourites />
                    </Route>
                    <Route path={routes.search.path}>
                      <LoadableSearchPage />
                    </Route>
                    <Route path={routes.signup.path}>
                      <LoadableSignUpPage />
                    </Route>
                    <Route path={routes.login.path}>
                      <LogInPage />
                    </Route>
                    <Route path={routes.myPages.path}>
                      <LoadableMyPages />
                    </Route>
                    <Route exact path={routes.forgotPassword.path}>
                      <ForgotPassword />
                    </Route>
                    <Route path={`${routes.resetPassword.path}/:token`}>
                      <ResetPassword />
                    </Route>
                    <Route
                      path="/preview"
                      render={props => (
                        <PreviewRoute
                          productQuery={ProductPreviewQuery}
                          categoryQuery={CategoryPreviewQuery}
                          pageQuery={PagePreviewQuery}
                          productPage={LoadableProductPage}
                          categoryPage={LoadableCategoryPage}
                          contentPage={LoadableContentPage}
                          StartPage={LoadableStartPage}
                          {...props}
                        />
                      )}
                    />
                    <DynamicRoute
                      routeQuery={routeQuery}
                      productPage={LoadableProductPage}
                      categoryPage={LoadableCategoryPage}
                      contentPage={LoadableContentPage}
                      notFoundPage={NotFound}
                      LoadingPage={LoadingPage}
                    />
                  </Switch>
                </Content>
                <Footer />
              </PaginationProvider>
            </ProductListProvider>
            <ModalRoot />
            <ScrollRestorationHandler
              ignoreForRouteTypes={['sortOrderChange', 'filterChange']}
            />
            <CookieConsent />
            <NewsletterPopup />
          </Container>
        </SiteContent>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
