import { theme } from './Theming';
import { css } from 'linaria';
import { getFontSize } from './helpers';
import '@jetshop/ui/Theme/sanitizeCss';

const styles = `
    html, body {
      height: 100%;
    }
  
    html, body, #root {
      min-height: 100%;
    }
    /* 
    * apply a natural box layout model to all elements, but allowing components
    * to change */
    html {
      box-sizing: border-box;
    }
    *, *:before, *:after {
      box-sizing: inherit;
    }
    html, body, #root {
      min-height: 100%;
    }
    #root {
      display: flex;
      flex-direction: column;
    }
    .ReactModal__Body--open {
      overflow: hidden;
    }
    .ReactModal__Overlay--after-open {
      overflow: scroll;
      z-index: 999;
      background-color: rgba(105, 105, 105, 0.75) !important;
    }

    html{
      font-size: 12px;
    }
    body{
      font-family: 'Arial', 'sans-serif';
      line-height: 1.5;
      letter-spacing: 1px;
      word-spacing: -0.1px;
      font-weight: 400;
      visibility: visible;
      margin: 0;
    }
    h1, h2, h3, h4, h5, h6, .secondary-font {
      text-transform: ${theme.typography.header.textTransform};
      letter-spacing: -1.5px;
      font-weight: 700;
      margin: 0;
    }
    h1 { ${getFontSize(theme.typography.header.fontSize[1])} }
    h2 { ${getFontSize(theme.typography.header.fontSize[2])} }
    h3 { ${getFontSize(theme.typography.header.fontSize[3])} }
    h4 { ${getFontSize(theme.typography.header.fontSize[4])} }
    h5 { ${getFontSize(theme.typography.header.fontSize[5])} }


    html.primary-font-ready{
      font-size: ${theme.typography.size}; 
      body{
        font-family: ${theme.typography.fontFamily};
        color: ${theme.colors.font};
        -webkit-font-smoothing: antialiased;
        font-weight:${theme.typography.fontWeight};
        background: ${theme.colors.white};
        line-height: 1.5;
        letter-spacing: ${theme.typography.letterSpacing}; 
      }
    }



    html.secondary-font-ready{
      h1, h2, h3, h4, h5, h6 {
        font-family: ${theme.typography.header.fontFamily};
        font-weight: ${theme.typography.header.fontWeight};
        letter-spacing: ${theme.typography.header.letterSpacing};
        line-height: 1.5;
        &.body-font{
          font-family: ${theme.typography.fontFamily};
          font-weight: ${theme.typography.fontWeight};
        }
        &.secondary{
          color:  ${theme.typography.header.secondColor.color} !important;
        }
        &.second-color{
          color:  ${theme.typography.header.secondColor.color} !important;
        }
        &.second-font{
          font-family:  ${
            theme.typography.header.secondFont.fontFamily
          } !important;
          font-weight:  ${
            theme.typography.header.secondFont.fontWeight
          } !important;
        }
        &.thin{
          font-weight: ${theme.weights.light} !important;
        }
        &.ultra{
          font-weight: ${theme.weights.ultra} !important;
        }
      }
      .secondary-font{
        font-family: ${theme.font.secondary};
        font-weight: ${theme.typography.header.fontWeight};
        letter-spacing: ${theme.typography.header.letterSpacing};
      }
    }
    
    h1, h2, h3, h4, h5, h6, p{
      &.normal{
        font-weight: ${theme.weights.normal} !important;
      }
      &.bold{
        font-weight: ${theme.weights.bold} !important;
      }
      &.thin{
        font-weight: ${theme.weights.light} !important;
      }
      &.ultra{
        font-weight: ${theme.weights.ultra} !important;
      }
    }



    a, a:active{
      color: ${theme.colors.font};
      text-decoration: none;
    }
    a:hover{

    }

    .seo-box a{
      color: ${theme.colors.link};
    }

    p { 
      font-size: 1rem; 
      line-height: 1.6;
      &.large{
        ${theme.above.md}{
          font-size: 1.4rem; 
        }
      }
    }
    
    strong{
      font-weight: ${theme.weights.bold};
    }

    img, iframe{
      max-width: 100%;
    }

    [data-flight-image-loaded="false"]{
      >div{
        font-size: 0;
      }
    }

    button, a, input {
      :focus {
        outline: none;
      }
    }

    button {
      cursor: pointer;
      padding: 0px;
      border: none;
      font: inherit;
      background: transparent;
      font-family: ${theme.typography.fontFamily};
      font-size:${theme.typography.fontSize};
      letter-spacing: ${theme.typography.letterSpacing};
      color: #333;
    }

    input, text-area, select {
      font-family: ${theme.typography.fontFamily};
      font-size:${theme.typography.fontSize};
    }


    .svg-inline--fa{
      height: 1em;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    ol{
      padding-left: 1.2rem;
    }

    p, dd {
      margin: 0;
      padding: 0;
    }

    select{
      font-size: 1rem;
    }
    
    .content-area{
      h1, h2, h3, h4, h5, h6{
        margin: 1rem 0 0.5rem 0;
      }
      p{
        margin: 0 0 1em 0;
      }
      >*:first-child{
        margin-top: 0 !important;
      }
      &.small{
        h1, h2, h3, h4, h5, h6{
          margin: 0.5em 0 1em 0;
        }
      }
      a{
        text-decoration: underline;
      }
    }

    .mnm-form-wrapper{
      *{
        font-family: ${theme.typography.fontFamily} !important;
        letter-spacing: ${theme.typography.letterSpacing} !important;
      }
      .js-newsmail-form-submit{
        background: ${theme.button.background} !important;
        color: ${theme.button.color} !important;
        font-weight: ${theme.button.fontWeight} !important;
        text-transform: ${theme.button.textTransform} !important;
        border-radius: 0 !important;
      }
      label{
        padding-right: 1.5rem;
        display: inline-block;
      }
      input[type="checkbox"]{
        margin-right: 5px;
      }
    }

    #inretrn{
      width: 100%;
      min-height: 700px;
    }

  `;

css`
  :global() {
    ${styles};
  }
`;
