export const isTouchDevice = () => {
  // Kontrollera om `ontouchstart` finns i `window`
  if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
    return true;
  }

  // För äldre versioner av Internet Explorer
  if (navigator.msMaxTouchPoints > 0) {
    return true;
  }

  return false;
};
